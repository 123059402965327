  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,400&family=Poppins:wght@200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* rect {
  fill: #FFFFFF00!important;
} */

text { 
  color: white!important;
}